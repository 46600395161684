import styled, { css, keyframes } from "styled-components";
import { media } from "../../common/MediaQueries";

export const ScalePadding = styled.div`
  position: relative;
  padding: 0 4px;
  width: 100%;
  height: 100%;

  @media ${media.phone} {
    padding: 0 7px;
  }
`;

export const ScaleTouchWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ScaleMinHeight = styled.div`
  position: relative;
  width: 100%;
  height: 12px;
  pointer-events: none;
`;

export const ScaleFilterScaleWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  color: #bad3fc;

  & svg {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  & svg:nth-child(3) {
    display: none;
  }

  @media ${media.phone} {
    height: 8px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;

    & svg:nth-child(1),
    & svg:nth-child(2) {
      display: none;
    }

    & svg:nth-child(3) {
      display: block;
    }
  }
`;

export const ScaleFilterScaleLine = styled.div`
  position: absolute;
  width: calc(100% + 14px);
  height: 1px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin: auto 0;
  background: #bad3fc;
  transform: translate(-50%, 0);
`;

export const ScaleFilterNumbersWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 14px;
  margin-top: 4px;
  position: relative;
  pointer-events: none;
`;

export const ScaleNumber = styled.div`
  position: absolute;
  top: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  font-variant: small-caps;
  color: #0b264c;
  transform: translate(-50%, 0);

  &:nth-child(even) {
    display: none;
  }

  @media ${media.phone} {
    &:nth-child(even) {
      display: block;
    }
  }
`;

interface IScaleDriver {
  isHint: boolean;
  isAnimationPlaying: boolean;
}

const hintKeyframes = keyframes`
	0% { 
		opacity: 100%; 
		transform: translate( -50%, 0 ) scale( .9, .9 );
	}

	20% { 
		opacity: 0%; 
		transform: translate( -50%, 0 ) scale( 1.4, 1.4 );
	}
`;

export const ScaleDriver = styled.div<IScaleDriver>`
  position: absolute;
  top: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translate(-50%, 0);
  background: ${({ theme }) => theme.colors.watermelon};

  ${({ isHint }) =>
    isHint &&
    css`
      opacity: 0;
      pointer-events: none;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timingfunction: cubic-bezier(0.5, 1, 0.89, 1);
      animation-delay: 600ms;
    `}

  ${({ isAnimationPlaying }) =>
    isAnimationPlaying &&
    css`
      animation-name: ${hintKeyframes};
    `}
`;

export const ScaleLine = styled.div`
  position: absolute;
  height: 1px;
  top: 50%;
  transform: translate(0, -50%);
  background: ${({ theme }) => theme.colors.watermelon};
`;
