import styled, { keyframes } from "styled-components";
import { media } from "../../common/MediaQueries";

const flash = keyframes`
  0%, 100% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }
`;

export const JobListLoadingWrapper = styled.div`
  will-change: opacity;
  animation: ${flash} 2s linear infinite;
`;

export const JobListLoadingItem = styled.div`
  height: 136px;
  border-bottom: 1px solid rgba(11, 38, 76, 0.1);
  padding: 15px 0;

  @media ${media.tablet} {
    position: relative;
    height: 92px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const JobListLoadingHeading = styled.div`
  height: 22px;
  margin-bottom: 10px;
  width: 60%;
  background: #f5f5f5;
  border-radius: 20px;

  @media ${media.tablet} {
    width: 25%;
    position: absolute;
    top: 12px;
    bottom: 36px;
    margin: auto 0;
    left: 85px;
  }
`;

export const JobListLoadingImage = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 6px;
  overflow: hidden;
  background: #f5f5f5;

  @media ${media.tablet} {
    position: absolute;
    left: 0;
    top: 12px;
    bottom: 12px;
    margin: auto 0;
    width: 60px;
    height: 60px;
  }
`;
