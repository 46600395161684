import normalizeEdges from "./normalizeEdges";
import normalizeJobs from "./normalizeJobs";

export default function normalizeJobsData(
  data: any,
  caseStudyToCompany: { caseStudyId: string; companyName: string }[]
) {
  const { allStrapiJobs, strapiJobsPage } = data;

  const {
    heroImage,
    heroText,
    caseStudies: unformattedCaseStudies,
    clientSuccesses: unformattedClientSuccesses,
    applyHeading,
    applySteps: unformattedApplySteps,
    jobs: unformattedOrderedJobs,
    referImage,
    referImageMobile,
    referPopupText,
    seo: unformattedSeo,
  } = strapiJobsPage;

  const allJobs = normalizeEdges(allStrapiJobs);

  const jobsFromList = unformattedOrderedJobs || [];

  const jobsWithOrdering: any = allJobs.map((job) => {
    const jobInList = jobsFromList
      .filter((jobFromList: any) => jobFromList.id === job.id)
      .pop();

    if (!jobInList) {
      return {
        ...job,
        jobListOrder: Infinity,
      };
    }

    const jobListOrder = jobsFromList.indexOf(jobInList);

    return {
      ...job,
      jobListOrder,
    };
  });

  const orderedJobs = [...jobsWithOrdering].sort((a, b) =>
    a.jobListOrder > b.jobListOrder ? 1 : -1
  );

  const jobs = normalizeJobs(orderedJobs);

  const caseStudies = (unformattedCaseStudies || [])
    .filter((caseStudy: any) => {
      const { heading, slug, media, leadQuote } = caseStudy;

      return heading && slug && media && leadQuote;
    })
    .map((caseStudy: any) => {
      const { id } = caseStudy;

      const { companyName = "" } =
        caseStudyToCompany.filter((item) => item.caseStudyId === id)[0] || {};

      return {
        ...caseStudy,
        companyName,
      };
    });

  const clientSuccesses = (unformattedClientSuccesses || []).filter(
    (success: any) => {
      const { heading, image, text } = success;

      return heading && image && text;
    }
  );

  const applySteps = (unformattedApplySteps || []).filter(
    (item: any) => item.heading && item.uid
  );

  return {
    heroImage,
    heroText,
    jobs,
    caseStudies,
    clientSuccesses,
    applyHeading,
    applySteps,
    referImage,
    referImageMobile,
    referPopupText,
    seo: unformattedSeo || {},
  };
}
