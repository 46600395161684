import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import GradientHero from "../components/GradientHero";
import JobsWithFilter from "../components/JobsWithFilter";
import JobsFilterContextProvider from "../contexts/JobsFilterContext";
import IJob from "../interfaces/IJob";
import IApplyStep from "../interfaces/IApplyStep";
import ApplyStepsContextProvider from "../contexts/ApplyStepsContext";
import IStrapiImage from "../interfaces/IStrapiImage";
import IClientTestemonial from "../interfaces/IClientTestemonial";
import IClientSuccesses from "../interfaces/IClientSuccess";
import { graphql } from "gatsby";
import normalizeJobsData from "../common/normalizeJobsData";
import "../fragments/Job";
import "../fragments/JobsPage";
import ISeo from "../interfaces/ISeo";
import JobsSEO from "../components/JobsSEO";
import TrackingContextProvider from "../contexts/UtmContext";
import ContactForm from "../components/ContactForm";
import JobListRefer from "../components/JobListRefer";
import ReferContextProvider from "../contexts/ReferContext";
import ICaseStudy from "../interfaces/ICaseStudy";

interface IProps {
  data: any;
}

const JobsPage: React.FC<IProps> = (props: any) => {
  const { pageContext, data: unformattedData }: any = props;

  const {
    filtered,
    caseStudyToCompany,
  }: {
    filtered: boolean;
    caseStudyToCompany: { caseStudyId: string; companyName: string }[];
  } = pageContext;

  const data = normalizeJobsData(unformattedData, caseStudyToCompany);

  const {
    heroText,
    heroImage,
    jobs,
    caseStudies,
    clientSuccesses,
    applyHeading,
    applySteps,
    referImage,
    referImageMobile,
    referPopupText,
    seo,
  }: {
    heroText: string;
    heroImage: IStrapiImage;
    jobs: IJob[];
    caseStudies: ICaseStudy[];
    clientSuccesses: IClientSuccesses[];
    applyHeading: string;
    applySteps: IApplyStep[];
    referImage: IStrapiImage;
    referImageMobile: IStrapiImage;
    referPopupText: string;
    seo: ISeo;
  } = data;

  const [showAll, setShowAll] = useState(filtered);

  useEffect(() => {
    if (showAll) return;

    setShowAll(true);
  }, []);

  return (
    <Layout
      headerLocation="jobs"
      skipFooterBorder={true}
    >
      <JobsSEO seo={seo} filtered={filtered} />
      <TrackingContextProvider>
        <ReferContextProvider content={referPopupText}>
          <GradientHero 
            text={heroText}
            image={heroImage}
            tag="h1"
          />
          <ApplyStepsContextProvider steps={applySteps} heading={applyHeading}>
            <JobsFilterContextProvider jobs={jobs} filtered={filtered}>
              <JobsWithFilter
                jobs={jobs}
                caseStudies={caseStudies}
                clientSuccesses={clientSuccesses}
                showAll={showAll}
              />
              <JobListRefer
                referImage={referImage}
                referImageMobile={referImageMobile}
                showAll={showAll}
              />
              <ContactForm extraMargin={true} showAll={showAll} />
            </JobsFilterContextProvider>
          </ApplyStepsContextProvider>
        </ReferContextProvider>
      </TrackingContextProvider>
    </Layout>
  );
};

export default JobsPage;

export const pageQuery = graphql`
  query {
    allStrapiJobs {
      edges {
        node {
          ...Job
          company {
            hqLocation
            name
            logo {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 100, quality: 100, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
    strapiJobsPage {
      ...JobsPage
    }
  }
`;
