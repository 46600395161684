import styled from "styled-components";

import { media } from "../../common/MediaQueries";

export const NarrowSectionStyled = styled.div`
  padding: 0 27px;

  @media ${media.desktop} {
    padding: 0 146px;
    max-width: 1596px;
    margin: 0 auto;
  }
`;
