import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const FilterSaveWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  margin-top: 27px;

  @media ${media.tablet} {
    display: none;
  }
`;

export const FilterSaveButton = styled.button`
  background: ${({ theme }) => theme.colors.watermelon};
  width: 135px;
  height: 30px;
  border-radius: 32px;
`;
