import styled from "styled-components";
import { css } from "styled-components";
import { media } from "../../common/MediaQueries";

interface IIsCompact {
  isCompact?: boolean;
}

export const Padding = styled.div<IIsCompact>`
  padding: 0 5px;
  display: flex;

  @media ${media.phone} {
    flex-grow: 1;
  }

  @media ${media.tablet} {
    padding: 0 10px;
  }

  @media ${media.desktop} {
    ${({ isCompact }) => (isCompact ? `padding: 0 10px;` : "padding: 0 26px;")}
  }
`;

interface IWrapper {
  isCompact?: boolean;
  isLink?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  width: 211px;
  padding: 9px 10px 14px 10px;
  flex-shrink: 0;
  background: #f0f5ff;
  border-radius: 7px;
  border: 2px solid #e9f0ff;

  ${({ isLink }) =>
    isLink &&
    css`
      cursor: pointer;

      &:hover {
        background: #e5ebf6;
        border-color: #dae2f2;
      }
    `}

  @media ${media.phone} {
    flex-grow: 1;
  }

  @media ${media.tablet} {
    ${({ isCompact }) =>
      isCompact
        ? `padding: 16px 10px 17px 16px;`
        : "padding: 16px 19px 15px 19px;"}
  }
`;

export const Row = styled.div<IIsCompact>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  ${({ isCompact }) => (isCompact ? `padding-top: 40px;` : "")}

  @media ${media.tablet} {
    align-items: flex-start;
    padding-top: 0;
    min-height: 100%;
  }
`;

export const ImageWrapper = styled.div<IIsCompact>`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;

  & img {
    border-radius: 50%;
  }

  ${({ isCompact }) =>
    isCompact &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    `}

  @media ${media.tablet} {
    order: 1;
    width: 68px;
    height: 68px;

    ${({ isCompact }) =>
      isCompact &&
      css`
        position: static;
        width: 47px;
        height: 47px;
        margin: 0;
      `}
  }
`;

export const TitlesWrapper = styled.div<IIsCompact>`
  font-weight: 500;
  width: 0;
  flex-grow: 1;

  ${({ isCompact }) =>
    isCompact
      ? css`
          width: 100%;
          order: 2;
        `
      : `margin-left: 10px;`}

  @media ${media.tablet} {
    order: 3;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 18px;
    margin-top: auto;
    font-weight: 400;

    ${({ isCompact }) =>
      !isCompact
        ? "flex-direction: column;"
        : css`
            margin-top: 9px;
            justify-content: flex-end;
          `}
  }
`;

export const Title = styled.div<IIsCompact>`
  ${({ isCompact }) => (isCompact ? "display: none;" : "")};

  color: #1b1b1b;

  @media ${media.desktop} {
    display: block;

    ${({ isCompact }) => isCompact && "color: #737F9D;"}
  }
`;

export const Position = styled.div<IIsCompact>`
  text-transform: uppercase;

  ${({ isCompact }) =>
    isCompact
      ? css`
          width: 100%;
          margin-top: 10px;
          font-variant: small-caps;
        `
      : "margin-top: 3px;"};

  @media ${media.desktop} {
    width: auto;

    ${({ isCompact }) =>
      isCompact &&
      css`
        color: #737f9d;
        margin-top: 0;
        font-weight: 500;
      `}
  }
`;

export const Quote = styled.div<IIsCompact>`
  width: 100%;
  margin-top: 10px;

  ${({ isCompact }) =>
    isCompact &&
    css`
      order: 1;
      text-align: center;
    `}

  @media ${media.tablet} {
    width: calc(100% - 84px);
    margin-top: 0;
    order: 2;
    font-weight: 600;
    text-align: left;

    ${({ isCompact }) =>
      isCompact ? "margin-left: 10px;" : "margin-left: 16px;"}
  }
`;

export const Dashes = styled.span`
  display: none;

  @media ${media.tablet} {
    display: inline;
  }
`;

export const Comma = styled.span<IIsCompact>`
  display: none;
  white-space: pre;
  color: #737f9d;

  @media ${media.desktop} {
    ${({ isCompact }) => isCompact && "display: inline;"};
    line-height: 17px;
  }
`;

export const NameMobile = styled.span`
  @media ${media.phone} {
    display: none;
  }
`;

export const NameDesktop = styled.span`
  display: none;

  @media ${media.phone} {
    display: inline;
  }
`;
