import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

interface ICheckboxListWrapper {
  desktopHideSearch?: boolean;
}

export const CheckboxListWrapper = styled.div<ICheckboxListWrapper>`
  padding: 16px 24px 28px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #fff;

  @media ${media.tablet} {
    position: relative;
    border-radius: 15px;

    ${({ desktopHideSearch }) =>
      desktopHideSearch
        ? css`
            padding: 10px 10px 10px 44px;
          `
        : css`
            padding: 20px 25px 10px 18px;
          `}
  }
`;

interface ICheckboxListItemsWrapper {
  desktopHideSearch?: boolean;
}

export const CheckboxListItemsWrapper = styled.div<ICheckboxListItemsWrapper>`
  height: 0;
  margin-top: 5px;
  border: 1px solid #ededed;
  border-radius: 4px;
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f7f7f7;
    border-radius: 21px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(11, 38, 76, 0.3);
    border-radius: 21px;
  }

  @media ${media.tablet} {
    height: auto;
    max-height: 216px;
    border: none;

    ${({ desktopHideSearch }) => (desktopHideSearch ? "margin-top: 0;" : "")}
  }
`;

interface ICheckboxListItem {
  show: boolean;
}

export const CheckboxListItem = styled.div<ICheckboxListItem>`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  padding: 17px;

  @media ${media.tablet} {
    padding: 10px 0 10px 6px;
  }
`;

export const CheckboxListCheckbox = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  margin: 0;
  background: #ececec;
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 4px;

  &:active,
  &:focus {
    outline: #658ac8 solid 2px;
  }

  &:checked {
    background-color: #0b264c;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDMuNzI3MjdMNC4yIDdMOSAxIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #0b264c;
  }
`;

export const CheckboxListLabel = styled.label`
  margin-left: 10px;
  flex-grow: 1;
  letter-spacing: -0.01em;
`;

export const ListEmpty = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 20px 40px 20px;
  font-size: 16px;
  line-height: 22px;
  color: #0b264c;
`;
