import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

interface I {
  desktopHide: boolean;
}

export const SearchInputWrapper = styled.div<I>`
  width: 100%;

  @media ${media.tablet} {
    ${({ desktopHide }) => (desktopHide ? "display: none;" : "")}
  }
`;

export const SearchInputInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface Props {
  square?: boolean;
}

export const SearchInputStyled = styled.input<Props>`
  width: 100%;
  background-color: #f7f7f7;
  border: none;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.01em;
  font-family: "Montserrat", sans-serif;

  ${({ square }) =>
    square
      ? css`
          padding-left: 39px;
          border-radius: 4px;
          height: 40px;
        `
      : css`
          padding-left: 37px;
          border-radius: 100px;
          height: 46px;
        `}

  &:placeholder {
    color: rgba(11, 38, 76, 0.5);
    letter-spacing: -0.01em;
    font-family: Montserrat;
  }
`;

export const SearchInputIconWrapper = styled.div<Props>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;

  bottom: 0;
  margin: auto 0;
  ${({ square }) =>
    square
      ? css`
          left: 17px;
          width: 10px;
          height: 10px;
        `
      : css`
          left: 13px;
          width: 15px;
          height: 15px;
        `}
`;
