import IJob from "../interfaces/IJob";
import ITechItem from "../interfaces/ITechItem";

export default function techFilterFunciton(job: IJob, selected: ITechItem[]) {
  return [
    ...job.mustHaveTechItems,
    ...job.niceToHaveTechItems,
  ].some((item: ITechItem) =>
    selected.some((selected) => selected.uid === item.uid)
  );
}
