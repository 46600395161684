import { MutableRefObject, useEffect, useRef } from "react";

interface IUseFocus {
  ref: MutableRefObject<HTMLElement | null>;
  showAll: boolean;
  selector: string;
}

export default function useFocus(opts: IUseFocus) {
  const { ref, showAll, selector } = opts;

  const fired = useRef(false);

  useEffect(() => {
    if (fired.current) return;

    if (!showAll) return;

    if (window.location.href.indexOf(selector) === -1) return;

    if (!ref.current) return;

    fired.current = true;

    setTimeout(() => {
      if (!document.scrollingElement) return;

      const rect = (ref.current as HTMLElement).getBoundingClientRect();

      const windowWidth = window.innerWidth;

      window.scrollTo(
        0,
        document.scrollingElement.scrollTop +
          rect.y -
          (windowWidth > 1024 ? 99 : 70)
      );
    }, 400);
  }, [showAll, fired, ref, selector]);
}
