import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const Padding = styled.div`
  padding: 0 5px;
  display: flex;

  @media ${media.phone} {
    flex-grow: 1;
  }

  @media ${media.tablet} {
    padding: 0 13px;
  }

  @media ${media.desktop} {
    padding: 0 26px;
  }
`;

export const Wrapper = styled.div`
  width: 214px;
  padding: 10px;
  padding-bottom: 16px;
  background: rgba(240, 245, 255, 0.7);
  border-radius: 10px;
  border: 2px solid rgba(233, 240, 255, 1);

  @media ${media.phone} {
    flex-grow: 1;
  }

  @media ${media.tablet} {
    position: relative;
    padding: 16px 24px 16px 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;

  @media ${media.tablet} {
    display: block;
  }
`;

export const ImagePadding = styled.div`
  padding-left: 1px;

  @media ${media.tablet} {
    padding-left: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 43px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 5px;
  overflow: hidden;

  & img {
    border-radius: 5px;
  }

  @media ${media.tablet} {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`;

export const TitlesWrapper = styled.div`
  margin-left: 9px;
  font-weight: 500;

  @media ${media.tablet} {
    margin-left: 58px;
    font-weight: 700;
  }
`;

export const Text = styled.div`
  margin-top: 7px;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    margin-top: 5px;
    margin-left: 58px;
  }
`;
