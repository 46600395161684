import React, { useContext, useEffect, useRef } from "react";
import { ReferContext } from "../../contexts/ReferContext";
import styled from "styled-components";
import StrapiImage from "../StrapiImage";
import IStrapiImage from "../../interfaces/IStrapiImage";
import { Typo14 } from "../Typography";
import { media } from "../../common/MediaQueries";
import aEvent from "../../common/aEvent";
import useFocus from "../../hooks/useFocus";

const Wrapper = styled.div`
  padding: 70px 27px 40px 27px;
  width: 100%;
  max-width: 1304px;
  text-align: center;
  margin: auto;

  @media ${media.tablet} {
    padding: 60px 27px 70px 27px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 321px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.tablet} {
    max-width: 701px;
  }
`;

const ImagePadding = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 22.118%;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${media.tablet} {
    padding-bottom: 9.985%;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 321px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.tablet} {
    margin-top: 0;
    max-width: 560px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 44px;
  border-radius: 10px;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.watermelon};

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.salmon};
    outline: none;
  }
`;

interface Props {
  referImage: IStrapiImage;
  referImageMobile: IStrapiImage;
  showAll: boolean;
}

const JobListRefer: React.FC<Props> = (props) => {
  const { referImage, referImageMobile, showAll } = props;

  const { setOpen } = useContext(ReferContext);

  const sectionRef = useRef<HTMLDivElement | null>(null);

  useFocus({
    ref: sectionRef,
    showAll,
    selector: "#job-list-refer",
  });

  if (!referImage || !referImageMobile) {
    return null;
  }

  return (
    <Wrapper ref={sectionRef}>
      <ImageWrapper>
        <ImagePadding id="jobs-page-refer-image-padding">
          <StrapiImage image={[referImageMobile, referImage]} />
        </ImagePadding>
      </ImageWrapper>
      <ButtonWrapper>
        <Button
          id="jobs-page-refer-button"
          onClick={() => {
            setOpen(true);

            aEvent({
              ea: "refer-popup-open",
              ec: "Refer Events",
              el: "jobs-page",
              ni: false,
            });
          }}
        >
          <Typo14 color="#fff" fontWeight="600">
            Refer a friend
          </Typo14>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default JobListRefer;
