import IJob from "../interfaces/IJob";

export default function timeFilterFunction(
  job: IJob,
  filterTimezone: number | null
) {
  if (filterTimezone === null) return true;

  const { timezoneGMTMin, timezoneGMTMax, timezoneCategory } = job;

  const timezoneCategoryIsValid =
    timezoneCategory &&
    typeof timezoneCategory.fromGMT === "number" &&
    typeof timezoneCategory.toGMT === "number"
      ? true
      : false;

  const jobFrom =
    timezoneCategory && timezoneCategoryIsValid
      ? timezoneCategory.fromGMT
      : timezoneGMTMin;
  const jobTo =
    timezoneCategory && timezoneCategoryIsValid
      ? timezoneCategory.toGMT
      : timezoneGMTMax;

  if (jobFrom === null || jobTo === null) return true;

  if (filterTimezone >= jobFrom && filterTimezone <= jobTo) return true;

  return false;
}
