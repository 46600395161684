import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobListCheckboxFilterTopRow = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 0;
  justify-content: center;
  position: relative;
  align-items: center;
  height: 55px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.08);

  @media ${media.tablet} {
    display: none;
  }
`;

export const JobListCheckboxFilterBackButton = styled.button`
  display: flex;
  align-items: center;
  width: 46px;
  height: 46px;
  position: absolute;
  left: 24px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
`;

export const JobListCheckboxFilterBackImageWrapper = styled.div`
  width: 21px;
  height: 10px;
`;

export const JobListBoxesWrapper = styled.div`
  display: flex;
  height: 41px;
  padding-top: 23px;
`;

export const JobListBox = styled.div`
  width: 36px;
  background: #f7f7f7;
  border-radius: 2px;
`;

export const JobListTo = styled.div`
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
