import styled from "styled-components";
import { media } from "../../common/MediaQueries";

interface I {
  open: boolean;
}

export const CheckboxPopup = styled.div<I>`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 440px;
  background: #fff;

  @media ${media.tablet} {
    position: absolute;
    height: auto;
    top: calc(100% + 8px);
    left: 0;
    box-shadow: -4px 0px 22px rgba(0, 0, 0, 0.02),
      9px 0px 28px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
  }
`;

interface ICheckboxCardArrow {
  desktopHideArrow?: boolean;
}

export const CheckboxCardArrow = styled.div<ICheckboxCardArrow>`
  display: none;

  @media ${media.tablet} {
    ${({ desktopHideArrow }) =>
      desktopHideArrow ? "display: none;" : "display: block;"}
    position: absolute;
    width: 21px;
    height: 21px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff;
    box-shadow: -4px 0px 22px rgba(0, 0, 0, 0.02),
      9px 0px 28px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
`;
