import styled from "styled-components";

import { media } from "../../common/MediaQueries";

export const JobListHeaderTable = styled.table`
  display: none;

  @media ${media.tablet} {
    display: table;
    width: 100%;
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(11, 38, 76, 0.1);
  }
`;
