import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

interface IContactWrapper {
  extraMargin?: boolean;
}

export const ContactWrapper = styled.div<IContactWrapper>`
  padding: 46px 27px 30px;
  background: rgba(240, 245, 255, 1);

  ${({ extraMargin }) => (extraMargin ? "margin-top:30px;" : "")}

  @media ${media.tablet} {
    padding-top: 60px;
    padding-bottom: 91px;
  }
`;

export const ContactCard = styled.div`
  padding: 29px 18px;
  background: #fff;
  border: 1px solid rgba(155, 192, 255, 0.38);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    max-width: 914px;
    margin: 0 auto;
    padding: 39px 21px;
  }
`;

export const ContactHeading = styled.h2`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0028em;
  color: #658ac8;

  @media ${media.tablet} {
    padding-left: 18px;
    width: 100%;
    font-size: 35px;
    line-height: 42px;
    font-weight: 700;
  }
`;

export const ContactFields = styled.div`
  margin-top: 10px;

  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;
    width: 100%;
  }
`;

interface IContactRow {
  full?: boolean;
}

export const ContactRow = styled.div<IContactRow>`
  position: relative;
  padding-top: 10px;

  @media ${media.tablet} {
    padding-top: 27px;
    padding-left: 18px;
    padding-right: 18px;

    ${({ full }) => (!full ? `width: 50%;` : "width: 100%;")}
  }
`;

export const ContactLabel = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1px;
  letter-spacing: -0.01em;
  font-variant: small-caps;
  text-transform: uppercase;
  line-height: 1.2rem;
  margin-bottom: 2px;

  @media ${media.tablet} {
    padding: 0 0 5px 0;
    line-height: 1.625rem;
    margin-bottom: 0;
  }
`;

interface IContactError {
  top: boolean;
}

export const ContactError = styled.div<IContactError>`
  position: absolute;
  pointer-events: none;

  ${({ top }) =>
    top
      ? css`
          bottom: 100%;
          right: 14px;
        `
      : css`
          top: 8px;
          left: 14px;
          height: 22px;
        `}

  @media ${media.tablet} {
    ${({ top }) =>
      !top &&
      css`
        top: 11px;
        left: 15px;
      `}
  }
`;

interface IContactInput {
  error: boolean;
}

export const ContactInputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const ContactInput = styled.input<IContactInput>`
  width: 100%;
  padding: 7px 14px;
  font-size: 16px;
  line-height: 26px;
  color: #0b264c;
  background: #fff;
  border: 1px solid rgba(101, 138, 200, 0.2);
  box-sizing: border-box;
  border-radius: 10px;
  font-family: "Montserrat", "Verdana", sans-serif;

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.sky};
    outline: none;
  }

  ${({ error }) =>
    error
      ? "border: 1px solid rgba(255, 80, 112, 0.2);"
      : "border: 1px solid rgba(101, 138, 200, .5);"}

  @media ${media.tablet} {
    padding: 11px 15px;
    font-size: 18px;
    letter-spacing: 0.0032em;
  }
`;

interface IContactTextarea {
  error: boolean;
}

export const ContactTextarea = styled.textarea<IContactTextarea>`
  display: block;
  width: 100%;
  padding: 7px 14px;
  resize: none;
  background: #fff;
  font-size: 16px;
  line-height: 26px;
  color: #0b264c;
  box-sizing: border-box;
  border-radius: 10px;
  height: 66px;
  font-family: "Montserrat", "Verdana", sans-serif;

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.sky};
    outline: none;
  }

  ${({ error }) =>
    error
      ? "border: 1px solid rgba(255, 80, 112, 0.2);"
      : "border: 1px solid rgba(101, 138, 200, .5);"}

  @media ${media.tablet} {
    padding: 11px 15px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.0032em;
  }
`;

export const ContactButtonWrapper = styled.div`
  margin-top: 22px;

  @media ${media.tablet} {
    width: 307px;
    margin-top: 30px;
    margin-left: auto;
    padding-right: 18px;
  }
`;

export const ContactSuccess = styled.div`
  height: 44px;

  @media ${media.tablet} {
    height: 60px;
  }
`;
